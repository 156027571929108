import { getEl, on } from "src/js/dom";
import { deactivateSearch } from "../search/search";

const menu = getEl(".header__menu");
const menuButton = getEl(".header__button--menu");
const menuActiveClass = "header__menu--active";

const search = getEl(".header__search");
const searchInput = getEl(".search__input");
const searchButton = getEl(".header__button--search");
const searchCloseButton = getEl(".search__close");
const searchActiveClass = "header__search--active";

on(menuButton, "click", () => {
  menu.classList.toggle(menuActiveClass);
});

on(searchButton, "click", () => {
  search.classList.toggle(searchActiveClass);
  setTimeout(() => {
    searchInput.focus();
  }, 100);
});

on(searchCloseButton, "click", () => {
  deactivateSearchBlock();
});

on(document.body, "click", (e) => {
  if (!search.contains(e.target) && !searchButton.contains(e.target)) {
    deactivateSearch();
    deactivateSearchBlock();
  }
});

export const deactivateSearchBlock = () => search.classList.remove(searchActiveClass);
