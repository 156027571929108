import './js/init';
import './style';
require.context('./img/sprites', true, /\.sprite\.svg$/);
require.context('./img', true, /\.svg$/);

import './modules/header/header';
import './modules/filter/filter.js';
import './modules/leaders/leaders.js';
import './modules/competitions/competitions.js';
import './modules/competition/competition.js';
import './modules/search/search.js';
import './modules/head/head.js';
import './modules/notFound/notFound.js';
import './modules/cities/cities.js';
import './modules/stats/stats.js';
