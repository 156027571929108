import axios from "axios";
import { getEl, on } from "src/js/dom";

const $container = getEl(".search");
const $input = getEl(".search__input");
const $popup = getEl(".search__popup");

on($input, "focusin", () => {
  $container.classList.add("search--active");
});

on($input, "input", (e) => {
  const value = e.target.value;
  if (!value) {
    $popup.innerHTML = "";
    return;
  }
  axios.get(`/members?name=${value}`).then(({ data }) => {
    let html = "";
    if (data.length) {
      html = data.map(({ name, id }) => `<a class="search__item" href="/members/${id}">${name}</a>`).join("");
    } else {
      html = `<div class="search__empty">Участник не найден</div>`;
    }
    $popup.innerHTML = html;
  });
});

export const deactivateSearch = () => $container.classList.remove("search--active");